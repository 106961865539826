import { useNuxtApp } from '#app';
import { Service } from '@/types/service';

import { PromoCard, PromoDetail, PromoCarouselBanner, HotelDeals, HotelDealsInfo } from '@/types/promo';

export const usePromoService = () => {
  const { $apifront } = useNuxtApp();

  // --------------------------------------------------------------------------
  // PROMO
  // --------------------------------------------------------------------------

  const getOngoingPromo = (lang: string, country: string) =>
    $apifront<Service<PromoCard[]>>(`/promo/ongoing?lang=${lang}&country=${country}`);

  const getPromoDetail = (slug: string, lang: string) =>
    $apifront<Service<PromoDetail>>(`/promo/detail?slug=${slug}&lang=${lang}`);

  // -------------------------------------------------------------------------
  // CAROUSEL PROMO
  // -------------------------------------------------------------------------

  const getCarousel = (params: { lang: string; country: string; platform: string; type: string }) =>
    $apifront<Service<PromoCarouselBanner[]>>('/carousel/get', { params });

  // -------------------------------------------------------------------------
  // HOTEL DEALS
  // -------------------------------------------------------------------------

  const getHotelDeals = (params: {
    lang: string;
    country: string;
    page: number;
    rows: number;
    deviceUi: string;
    currency: string;
  }) => $apifront<Service<HotelDeals>>('/contents/hotel/deals', { params });

  const getHotelDealsInfo = (params: { lang: string; country: string; deviceUi: string }) =>
    $apifront<Service<HotelDealsInfo>>('/contents/hotel/deal-info', { params });

  return {
    getOngoingPromo,
    getPromoDetail,
    getCarousel,
    getHotelDeals,
    getHotelDealsInfo,
  };
};
